<template>
  <div class="record">
    <div class="table-wrap">
      <div class="top-box">
        <h3>{{ $t("demand.requirement_reporting_record") }}</h3>
        <div class="ctrl">
          <template v-if="dataSource.status == 1">
            <a-button type="link" class="disabled-btn">
              <a-popover>
                <template #content>
                  {{ $t("demand.collection_has_not_started_yet") }}
                </template>
                {{ $t("demand.add_demand") }}
              </a-popover>
            </a-button>
            <!-- 新增需求 -->
            <a-button type="link" class="disabled-btn">
              <a-popover>
                <template #content>
                  {{ $t("demand.collection_has_not_started_yet") }}
                </template>
                {{ $t("demand.batch_reporting") }}
              </a-popover>
            </a-button>
            <!-- 批量上报 -->
            <a-button type="link" class="disabled-btn">
              <a-popover>
                <template #content>
                  {{ $t("demand.collection_has_not_started_yet") }}
                </template>
                {{ $t("demand.batch_delete") }}
              </a-popover>
            </a-button>
            <!-- 批量删除 -->
          </template>
          <template v-else-if="dataSource.status == 3">
            <a-button type="link" class="disabled-btn">
              <a-popover>
                <template #content>
                  {{ $t("demand.solicitation_has_ended") }}
                </template>
                {{ $t("demand.add_demand") }}
              </a-popover>
            </a-button>
            <!-- 新增需求 -->
            <a-button type="link" class="disabled-btn">
              <a-popover>
                <template #content>
                  {{ $t("demand.solicitation_has_ended") }}
                </template>
                {{ $t("demand.batch_reporting") }}
              </a-popover>
            </a-button>
            <!-- 批量上报 -->
            <a-button type="link" class="disabled-btn">
              <a-popover>
                <template #content>
                  {{ $t("demand.solicitation_has_ended") }}
                </template>
                {{ $t("demand.batch_delete") }}
              </a-popover>
            </a-button>
            <!-- 批量删除 -->
          </template>
          <template v-else-if="dataSource.status == 2">
            <a-button type="link" @click="add">
              {{ $t("demand.add_demand") }}
            </a-button>
            <!-- 新增需求 -->
            <a-button type="link" @click="reports">
              {{ $t("demand.batch_reporting") }}
            </a-button>
            <!-- 批量上报 -->
            <a-button type="link" @click="dels">
              {{ $t("demand.batch_delete") }}
            </a-button>
            <!-- 批量删除 -->
          </template>
        </div>
      </div>
      <a-table
        :scroll="{ x: 884 }"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
          columnWidth: 34,
        }"
        :columns="columns"
        :data-source="state.dataSource"
        rowKey="demandId"
        :bordered="false"
        :pagination="pagination"
        :loading="state.loading"
        @change="handleTableChange"
      >
        <template #trainName="{ text }">
          <a-tooltip placement="topLeft" :title="text">
            {{ text }}
          </a-tooltip>
        </template>
        <template #times="{ record }">
          {{ dateFormat(record.startTime, "YYYY-MM-DD") }} ~
          {{ dateFormat(record.endTime, "YYYY-MM-DD") }}
        </template>
        <template #budget="{ text }">
          <template v-if="budgetAmount.dataFormat === 'wanyuan'">
            {{ (text / 10000).toFixed(2) }}
          </template>
          <template v-else>
            {{ text }}
          </template>
          {{ budgetAmount.unit }}
        </template>
        <template #reportTime="{ text }">
          {{ dateFormat(text) }}
        </template>
        <template #status="{ text }">
          <span class="green" v-if="text == 2">{{
            $t("demand.reported")
          }}</span>
          <span v-else-if="text == 1">{{ $t("demand.not_reported") }}</span>
          <span class="orange" v-else-if="text == 30">{{
            $t("demand.rejected")
          }}</span>
        </template>
        <template #action="{ record }">
          <div class="action">
            <template v-if="record.status == 1 || record.status == 30">
              <template v-if="dataSource.status == 2">
                <a-button type="link" @click="edit(record)">{{
                  $t("Pub_Btn_Edit")
                }}</a-button>
                <a-popconfirm
                  :title="$t('demand.sure_del')"
                  @confirm="del(record.demandId)"
                >
                  <a-button type="link">{{ $t("CM_Delete") }}</a-button>
                </a-popconfirm>
                <a-popconfirm
                  :title="$t('demand.sure_repor')"
                  @confirm="report(record.demandId)"
                >
                  <a-button type="link">{{ $t("demand.reporting") }}</a-button>
                </a-popconfirm>
              </template>
            </template>
            <template v-else-if="record.status == 2">
              <a-button type="link" @click="view(record)">{{
                $t("cm_view")
              }}</a-button>
            </template>
          </div>
        </template>
      </a-table>
    </div>

    <a-modal
      :title="currentDetail.trainName"
      :width="876"
      :bodyStyle="{ padding: 0 }"
      :centered="true"
      :footer="null"
      :visible="detailVisible"
      @cancel="detailVisible = false"
    >
      <div class="detail-warp">
        <div class="detail-mod">
          <div class="mod-tit">
            {{ $t("demand.requirement_information") }}
          </div>
          <div class="mod-rows">
            <p>
              <span class="label">{{ $t("demand.form.training_name") }}：</span>
              {{ currentDetail.trainName }}
            </p>
            <p>
              <span class="label">{{ $t("demand.form.holding_time") }}：</span>
              {{ dateFormat(currentDetail.startTime, "YYYY-MM-DD") }} ~
              {{ dateFormat(currentDetail.endTime, "YYYY-MM-DD") }}
            </p>
            <p>
              <span class="label"
                >{{ $t("demand.form.training_budget") }}：</span
              >
              <template v-if="budgetAmount.dataFormat === 'wanyuan'">
                {{ (currentDetail.budget  / 10000).toFixed(2) }}
              </template>
              <template v-else>
                {{ currentDetail.budget  }}
              </template>
              {{ budgetAmount.unit }}
            </p>
            <p>
              <span class="label"
                >{{ $t("demand.form.content_of_training_needs") }}：</span
              >
              {{ currentDetail.content }}
            </p>
            <p>
              <span class="label"
                >{{ $t("demand.form.reason_for_training_need") }}：</span
              >
              {{ currentDetail.reason }}
            </p>
          </div>
        </div>
        <div class="detail-mod">
          <div class="mod-tit">
            {{ $t("demand.reporting_information") }}
          </div>
          <div class="mod-rows">
            <p>
              <span class="label">{{ $t("demand.reportperson") }}：</span>
              <OpenData
                type="userName"
                :openid="currentDetail.reportUserName"
              />
            </p>
            <p>
              <span class="label"
                >{{ $t("demand.reporting_department") }}：</span
              >
              <OpenData
                type="departmentName"
                :openid="currentDetail.departmentName"
              />
            </p>
            <p>
              <span class="label">{{ $t("demand.reporting_time") }}：</span>
              {{ dateFormat(currentDetail.reportTime) }}
            </p>
          </div>
        </div>
        <div class="detail-mod">
          <div class="mod-tit">
            {{ $t("demand.develop_information") }}
          </div>
          <div class="mod-rows">
            <p>
              <span class="label">{{ $t("CM_Status") }}：</span>
              <template v-if="currentDetail.formulateStatus == 0">
                {{ $t("demand.not_formulated") }}
              </template>
              <template v-if="currentDetail.formulateStatus == 1">
                {{ $t("demand.established") }}
              </template>
            </p>
            <p>
              <span class="label">{{ $t("demand.set_time") }}：</span>
              {{ dateFormat(currentDetail.formulateTime) }}
            </p>
            <p>
              <span class="label">{{ $t("demand.training_plan_name") }}：</span>
              {{ currentDetail.planName || "--" }}
            </p>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { reactive, toRefs, createVNode, getCurrentInstance } from "vue";
import { useFetchData } from "@/utils/useFetchData";
import { demandSaveList, demandDel, demandReport } from "@/api/demand";
import { dateFormat } from "@/utils/tools";
import { ExclamationCircleFilled } from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";
import OpenData from "@/components/OpenData.vue";
export default {
  components: {
    OpenData,
  },
  props: {
    dataSource: {
      type: Object,
      default: () => {},
    },
    budgetAmount: {
      type: Object,
      default: () => {},
    },
  },
  setup(props, { emit }) {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const stateData = reactive({
      selectedRowKeys: [],
      columns: [
        {
          title: $t("demand.form.training_name"),
          // title: "需求名称",
          dataIndex: "trainName",
          width: 160,
          ellipsis: true,
          resizable: true,
          slots: { customRender: "trainName" },
        },
        {
          title: $t("demand.form.holding_time"),
          // title: "举办时间",
          width: 204,
          slots: { customRender: "times" },
          align: "center",
        },
        {
          title: $t("demand.budget"),
          // title: "预算费用",
          dataIndex: "budget",
          slots: { customRender: "budget" },
          width: 120,
          align: "center",
        },
        {
          title: $t("demand.report_status"),
          // title: "上报状态",
          dataIndex: "status",
          slots: { customRender: "status" },
          width: 90,
          align: "center",
        },
        {
          title: $t("demand.reporting_time"),
          // title: "上报时间",
          dataIndex: "reportTime",
          slots: { customRender: "reportTime" },
          width: 140,
          align: "center",
        },
        {
          title: $t("cm_operate"),
          // title: "操作",
          dataIndex: "action",
          width: 158,
          slots: { customRender: "action" },
          align: "center",
        },
      ],
      detailVisible: false,
      currentDetail: {},
    });

    const getList = (params) => {
      let form = {};
      form.page = params.current;
      form.pageSize = params.pageSize;
      form.taskId = props.dataSource.taskId;
      form.formulateStatus = -1;

      return demandSaveList(form).then((res) => {
        return {
          success: true,
          data: res.data.list,
          current: res.data.page,
          pageSize: res.data.pageSize,
          total: res.data.totals,
        };
      });
    };

    const {
      stripe,
      reload,
      setPageInfo,
      context: state,
    } = useFetchData(getList, {
      current: 1,
      pageSize: 10,
      tableSize: "middle",
      stripe: false,
    });
    const handleTableChange = ({ current, pageSize }, filters, sorter) => {
      setPageInfo({
        current,
        pageSize,
        sortKey: sorter.field,
        sort: sorter.order,
        ...filters,
      });
      reload();
    };
    const { total, pageSize, current } = toRefs(state);
    const pagination = reactive({
      showSizeChanger: true,
      showQuickJumper: false,
      pageSizeOptions: ["10", "30", "50", "100"],
      current,
      pageSize,
      total,
      onChange: (page, pageSize) => {
        setPageInfo({ current: page, pageSize });
        reload();
      },
      onShowSizeChange: (current, size) => {
        setPageInfo({ current, pageSize: size });
        reload();
      },
    });

    const onSelectChange = (selectedRowKeys) => {
      stateData.selectedRowKeys = selectedRowKeys;
    };

    const add = () => {
      emit("add");
    };

    const reports = () => {
      if (stateData.selectedRowKeys.length) {
        Modal.confirm({
          title: $t("demand.sure_repors"),
          icon: createVNode(ExclamationCircleFilled),
          onOk() {
            demandReport(stateData.selectedRowKeys).then((res) => {
              stateData.selectedRowKeys = [];
              proxy.$message.success($t("CM_Success"));
              reload();
            });
          },
        });
      } else {
        proxy.$message.warning($t("demand.report_tip"));
      }
    };

    const dels = () => {
      if (stateData.selectedRowKeys.length) {
        Modal.confirm({
          title: $t("demand.sure_dels"),
          icon: createVNode(ExclamationCircleFilled),
          onOk() {
            demandDel(stateData.selectedRowKeys).then((res) => {
              stateData.selectedRowKeys = [];
              proxy.$message.success($t("CM_Success"));
              reload();
            });
          },
        });
      } else {
        proxy.$message.warning($t("demand.del_tip"));
      }
    };

    const view = (data) => {
      console.log(data);
      stateData.detailVisible = true;
      stateData.currentDetail = data;
    };

    const edit = (data) => {
      emit("add", data);
    };

    const del = (id) => {
      demandDel([id]).then((res) => {
        proxy.$message.success($t("CM_Success"));
        reload();
      });
    };

    const report = (id) => {
      demandReport([id]).then((res) => {
        proxy.$message.success($t("CM_Success"));
        reload();
      });
    };

    return {
      dateFormat,
      ...toRefs(stateData),
      state,
      pagination,
      handleTableChange,
      onSelectChange,
      add,
      reports,
      dels,
      view,
      edit,
      del,
      report,
    };
  },
};
</script>

<style lang="less" scoped>
.record {
  background: #fff;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 0px 15px 0px rgba(148, 148, 148, 0.15);
  margin-top: 20px;
  .top-box {
    .mixinFlex(space-between; center);
    padding-bottom: 16px;
    h3 {
      color: #333;
      font-size: 20px;
      font-weight: 600;
      line-height: 26px;
      margin: 0;
      word-break: break-all;
    }
    .ctrl {
      margin-right: -15px;
      .disabled-btn {
        color: #999;
        cursor: no-drop;
      }
    }
  }
}
.table-wrap {
  .action {
    ::v-deep(.ant-btn) {
      padding-right: 4px;
      padding-left: 0;
      margin-right: 10px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
.green {
  color: #22cf33;
}
.orange {
  color: #e8673e;
}
::v-deep(.ant-table-thead > tr > th) {
  padding: 12px 4px;
}
::v-deep(.ant-table-tbody > tr > td) {
  padding: 8px 4px;
}
.detail-warp {
  max-height: 628px;
  padding: 24px 24px 0;
  overflow-y: auto;
  .detail-mod {
    padding-bottom: 24px;
    .mod-tit {
      font-size: 16px;
      line-height: 22px;
      margin: 0 0 8px 0;
      padding-left: 11px;
      position: relative;
      color: #333;
      font-weight: 600;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        top: 4px;
        width: 3px;
        height: 16px;
        background-color: var(--theme);
      }
    }
    .mod-rows {
      font-size: 16px;
      p {
        margin-bottom: 8px;
        color: #333;
      }
    }
  }
}
</style>
